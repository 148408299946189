import gsap from 'gsap';
import $ from '../core/Dom';
import Components from '../core/Components';
import { animatedScroll } from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import { REDUCED_MOTION_CHANGED } from '../lib/events';

export default form => {
    
    const input = form.querySelector('input');
    const announcer = document.getElementById('teasers-search-announcer');
    const listing = document.getElementById('teasers');
    
    const { progressText } = form.dataset;
    
    let request;
    let tween;
    let reduceMotion = false;
    
    async function search() {
        if (tween) {
            tween.kill();
        }
        announcer.textContent = progressText;
        gsap.killTweensOf(listing);
        gsap.set(listing, { clearProps: 'height' });
        const minHeight = parseInt(getComputedStyle(listing).getPropertyValue('--minh').replace('px', ''), 10);
        tween = gsap.timeline()
            .to(listing, {
                opacity: 0,
                duration: !reduceMotion ? 0.3 : 0
            }, 0);
        const url = new URL(form.action);
        const { value } = input;
        if (value) {
            url.searchParams.set(input.name, value);
        }
        const response = await fetch(url);
        const html = await response.text();
        if (!html) {
            return;
        }
        const replaceHtml = () => {
            if (tween.isActive()) {
                requestAnimationFrame(replaceHtml);
                return;
            }
            const $html = $('<div />').html(html);
            const { height } = listing.getBoundingClientRect();
            const newListing = $html.find('#teasers').get(0);
            listing.innerHTML = newListing.innerHTML;
            Components.init(listing);
            const { height: newHeight } = listing.getBoundingClientRect();
            const newAnnouncer = $html.find('#teasers-search-announcer').get(0);
            if (newAnnouncer) {
                announcer.textContent = newAnnouncer.textContent;
            }
            tween = gsap.timeline({
                onComplete() {
                    tween.kill();
                    tween = null;
                }
            })
                .to(listing, {
                    opacity: 1,
                    duration: !reduceMotion ? 0.3 : 0
                }, 0);
            if (newHeight < height) {
                tween
                    .fromTo(listing, {
                        height
                    }, {
                        height: newHeight,
                        duration: !reduceMotion ? 0.5 : 0,
                        ease: 'Quad.easeOut'
                    }, 0)
                    .set(listing, { clearProps: 'height' });
            }
        };
        requestAnimationFrame(replaceHtml);
    }
    
    const onReduceMotionChange = (key, data) => {
        reduceMotion = data.reduceMotion;
    };
    
    Dispatch.on(REDUCED_MOTION_CHANGED, onReduceMotionChange, true);
    
    const onSubmit = e => {
        e.preventDefault();
        animatedScroll(form);
        search();
    };
    
    form.addEventListener('submit', onSubmit);
    
    return {
        destroy() {
            form.removeEventListener('submit', onSubmit);
            Dispatch.off(REDUCED_MOTION_CHANGED, onReduceMotionChange);
        }
    };
    
};
