import Bricks from 'bricks.js';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, { sizes }) => {
    
    const bricks = Bricks({
        container: el,
        packed: 'packed',
        sizes: sizes.map(size => ({ gutter: 0, ...size }))
    });
    
    const reset = () => {
        $(el).css({ width: '', height: '', position: '' });
        $(el.children).css({ width: '', height: '', position: '' });
    };
    
    const maybePack = () => {
        if ($(el.children).width() >= $(el).width()) {
            return;
        }
        bricks.pack();
    };
    
    const onResize = () => {
        reset();
        maybePack();
    };
    
    Viewport.on('resize', onResize);
    
    maybePack();
    
    return {
        destroy() {
            Viewport.off('resize', onResize);
            reset();
        }
    };

};
