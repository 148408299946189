import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { getMaxScroll } from '../lib/helpers';
import Dispatch from '../core/Dispatch';
import { DOM_CHANGED, MENU_CLOSE, MENU_OPEN, PROGRAMMATIC_SCROLL_END, PROGRAMMATIC_SCROLL_START, REDUCED_MOTION_CHANGED } from "../lib/events";

gsap.registerPlugin(ScrollToPlugin);

export default el => {
    
    const $el = $(el);
    const inner = el.firstElementChild;
    const burger = $el.find('[data-burger]').get(0);
    const menu = burger.nextElementSibling;
    const quickMenu = $el.find('[data-quickmenu]').get(0);
    const isFixed = inner.classList.contains('fixed');
    
    let {
        scrollTop: prevScrollTop,
        width: viewW,
        height: viewH
    } = Viewport;
    
    let headerHeight = $(inner).height();
    let maxScroll = getMaxScroll();
    let isSticky = isFixed;
    let isHidden = false;
    let menuIsOpen = false;
    let preventShow = false;
    
    let reduceMotion = false;
    
    const show = (tween = true) => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.timeline()
                .to(inner, {
                    yPercent: 0,
                    duration: 0.3
                }, 0);
        } else {
            gsap.set(inner, { yPercent: 0 });
        }
    };
    
    const hide = (tween = true) => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.to(inner, {
                yPercent: -100,
                duration: 0.3
            });
        } else {
            gsap.set(inner, { yPercent: -100 });
        }
    };
    
    const stick = () => {
        if (isSticky || isFixed) {
            return;
        }
        isSticky = true;
        inner.classList.replace('absolute', 'fixed');
    };
    
    const unstick = () => {
        if (!isSticky || isFixed) {
            return;
        }
        isSticky = false;
        inner.classList.replace('fixed', 'absolute');
    };
    
    const onScroll = (tween = true, force = false) => {
        const { scrollY: scrollTop } = window;
        if (!menuIsOpen) {
            let direction = 'up';
            if (prevScrollTop) {
                direction = scrollTop > prevScrollTop ? 'down' : 'up';
            }
            // Make sticky?
            const top = Math.round(scrollTop);
            if (isSticky) {
                if (top <= 0) {
                    unstick();
                    show(false);
                    return;
                }
                if (menuIsOpen) {
                    show(false);
                }
                if (!force && Math.abs(top - prevScrollTop) <= 20) {
                    return;
                }
                const threshold = isFixed ? (viewH / 3) : headerHeight;
                if (direction === 'down' && top >= threshold) {
                    hide(tween);
                } else if (direction === 'up' && scrollTop < maxScroll && !preventShow) {
                    show(tween);
                }
            } else if (top >= headerHeight) {
                stick();
                hide(false);
                return;
            }
        }
        prevScrollTop = scrollTop;
    };
    
    let burgerTl;
    
    const openMenu = () => {
        if (menuIsOpen) {
            return;
        }
        menuIsOpen = true;
        show(false);
        menu.hidden = false;
        document.body.classList.add('menu-open');
        burger.setAttribute('aria-expanded', 'true');
        if (!isSticky) {
            gsap.to(window, { scrollTo: 0, duration: window.scrollY > 0 ? 0.3 : 0, onComplete: stick });
        }
        if (!burgerTl) {
            const label = burger.firstElementChild;
            const burgerBars = Array.from(burger.querySelectorAll('[data-bar]'));
            const { height: burgerHeight } = burgerBars[0].parentNode.getBoundingClientRect();
            const barsTl = gsap.timeline()
                .to(burgerBars[0], { y: Math.round(burgerHeight / 2) - 1, duration: 0.3, ease: 'Back.easeIn' }, 'y')
                .to(burgerBars[2], { y: -(Math.round(burgerHeight / 2) - 1), duration: 0.3, ease: 'Back.easeIn' }, 'y')
                .to(burgerBars[1], { scaleX: 0, duration: 0.3, ease: 'Back.easeIn' }, 'y')
                .to(burgerBars[0], { rotate: 45, duration: 0.3, ease: 'Back.easeOut' }, 'rotate')
                .to(burgerBars[2], { rotate: -45, duration: 0.3, ease: 'Back.easeOut' }, 'rotate');
            const labelTl = gsap.timeline()
                .to(label, { opacity: 0, duration: 0.3, ease: 'Sine.easeIn' }, 'out')
                .to(label, { x: 10, duration: 0.3, ease: 'Quint.easeIn' }, 'out')
                .add(() => {
                    const openLabel = label.querySelector('[data-open-label]');
                    const closeLabel = label.querySelector('[data-close-label]');
                    if (menuIsOpen) {
                        gsap.set(openLabel, { opacity: 0 });
                        gsap.set(closeLabel, { opacity: 1 });
                    } else {
                        gsap.set(openLabel, { opacity: 1 });
                        gsap.set(closeLabel, { opacity: 0 });
                    }
                })
                .to(label, { opacity: 1, duration: 0.3, ease: 'Sine.easeIn' }, 'in')
                .fromTo(label, { x: -10 }, { x: 0, duration: 0.3, ease: 'Quint.easeOut', immediateRender: false }, 'in');
            burgerTl = gsap.timeline({ paused: true })
                .add(barsTl, 0)
                .add(labelTl, 0);
        }
        gsap.killTweensOf(menu);
        const menuTl = gsap.timeline({ paused: true })
            .fromTo(menu, { opacity: 0 }, { opacity: 1, duration: !reduceMotion ? 0.5 : 0 }, 'anim');
        const links = Array.from(menu.querySelectorAll('a'));
        if (links.length) {
            const spans = links.map(link => link.children);
            gsap.killTweensOf(spans);
            menuTl
                .fromTo(spans, { x: -10 }, { x: 0, ease: 'Quint.easeOut', duration: !reduceMotion ? 0.75 : 0 }, 'anim+=0.3')
                .fromTo(spans, { opacity: 0 }, { opacity: 1, duration: !reduceMotion ? 0.5 : 0 }, 'anim+=0.3');
        }
        if (!reduceMotion) {
            menuTl.timeScale(1.5).play();
            burgerTl.timeScale(1.5).play();
        } else {
            menuTl.pause(menuTl.totalDuration(), false);
            burgerTl.pause(burgerTl.totalDuration(), false);
        }
        Viewport.lockTabbing(el, burger);
        if (quickMenu) {
            $(quickMenu).find('a').attr({
                tabIndex: '-1',
                'aria-hidden': 'true'
            });
        }
    };
    
    const closeMenu = (tween = !reduceMotion) => {
        if (!menuIsOpen) {
            return;
        }
        const afterClose = () => {
            menu.hidden = true;
            document.body.classList.remove('menu-open');
        };
        burger.setAttribute('aria-expanded', 'false');
        menuIsOpen = false;
        onScroll(false, true);
        Viewport.releaseTabbing(burger);
        if (quickMenu) {
            $(quickMenu).find('a').attr({
                tabIndex: '',
                'aria-hidden': ''
            });
        }
        gsap.killTweensOf(menu);
        if (tween) {
            burgerTl.reverse();
            gsap.to(menu, { opacity: 0, duration: 0.3, onComplete: afterClose });
        } else {
            burgerTl.pause(0, false);
            gsap.set(menu, { opacity: 0 });
            afterClose();
        }
    };
    
    const onResize = (updateScroll = true, force = false) => {
        if (!force && Viewport.width === viewW && Math.abs(Viewport.height - viewH) < 150 && getMaxScroll() === maxScroll) {
            return;
        }
        viewW = Viewport.width;
        viewH = Viewport.height;
        headerHeight = $(inner).height();
        maxScroll = getMaxScroll();
        if (updateScroll) {
            onScroll();
        }
    };
    
    let raf;
    const scrollHandler = () => {
        if (raf) {
            cancelAnimationFrame(raf);
        }
        raf = requestAnimationFrame(() => {
            raf = null;
            onScroll();
        });
    };
    window.addEventListener('scroll', scrollHandler);
    Viewport.on('resize', onResize);
    
    onResize(false, true);
    onScroll(false, true);
    
    let mutationObserver;
    if (window.MutationObserver) {
        mutationObserver = new MutationObserver(() => {
            onResize();
        });
        mutationObserver.observe(document.body, {
            attributes: false,
            childList: true,
            subtree: true
        });
    }
    
    Dispatch.on(DOM_CHANGED, onResize);
    
    const onProgrammaticScrollStart = () => {
        if (!isHidden || !isSticky) {
            return;
        }
        preventShow = true;
    };
    
    const onProgrammaticScrollEnd = () => {
        preventShow = false;
    };
    
    const onBurgerClick = () => {
        if (menuIsOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };
    
    Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
    Dispatch.on(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
    
    $(el).on('focusin', 'a,button', show);
    $(burger).on('click', onBurgerClick);
    
    const onBodyKeyUp = e => {
        if (!menuIsOpen) {
            return;
        }
        if (e.key === 'Escape') {
            closeMenu();
        }
    };
    
    $('body').on('keyup', onBodyKeyUp);
    
    const onReduceMotionChange = (key, data) => {
        reduceMotion = data.reduceMotion;
    };
    
    Dispatch.on(REDUCED_MOTION_CHANGED, onReduceMotionChange, true);
    
    return {
        destroy() {
            if (mutationObserver) {
                mutationObserver.disconnect();
                mutationObserver = null;
            }
            $(el).off('focusin');
            $(burger).off('click');
            $('body').off('keyup', onBodyKeyUp);
            window.removeEventListener('scroll', scrollHandler);
            Viewport.off('resize', onResize);
            Dispatch.off(DOM_CHANGED, onResize);
            Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScrollStart);
            Dispatch.off(PROGRAMMATIC_SCROLL_END, onProgrammaticScrollEnd);
            Dispatch.off(REDUCED_MOTION_CHANGED, onReduceMotionChange);
            closeMenu(false);
        }
    };
    
};
