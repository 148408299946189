import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { PROGRAMMATIC_SCROLL_END, PROGRAMMATIC_SCROLL_START, REDUCED_MOTION_CHANGED } from "./events";

gsap.registerPlugin(ScrollToPlugin);

// Reduced motion
export const reducedMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)');
const onReduceMotionChange = () => {
    Dispatch.emit(REDUCED_MOTION_CHANGED, { reduceMotion: !!reducedMotionQuery.matches });
};
reducedMotionQuery.addEventListener('change', onReduceMotionChange);
onReduceMotionChange();

export const getMaxScroll = () => (Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - Viewport.height);

/**
 * @returns {boolean}
 */
export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

/**
 * @param y
 * @returns {gsap.core.Tween}
 */
export const animatedScroll = (y, vars = {}, offsetY = 0) => gsap.to(window, {
    scrollTo: {
        y,
        offsetY,
        autoKill: !isTouch()
    },
    duration: !reducedMotionQuery.matches ? 0.75 : 0,
    ease: 'Cubic.easeInOut',
    onStart() {
        Dispatch.emit(PROGRAMMATIC_SCROLL_START);
    },
    onComplete() {
        setTimeout(() => {
            Dispatch.emit(PROGRAMMATIC_SCROLL_END);
        }, 0);
    },
    ...vars
});
