import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { REDUCED_MOTION_CHANGED } from '../lib/events';

export default details => {

    const summary = details.querySelector('summary');
    const content = summary.nextElementSibling;
    
    let isOpen = details.open;
    if (isOpen) {
        details.classList.add('open');
    }
    
    let tl;
    let reduceMotion = false;
    
    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        if (tl) {
            tl.kill();
        }
        details.open = true;
        details.classList.add('open');
        const duration = !reduceMotion ? 0.3 : 0;
        tl = gsap.timeline()
            .fromTo(content, { height: 0 }, { height: 'auto', duration }, 0)
            .fromTo(content, { opacity: 0 }, { opacity: 1, duration }, 0);
    };
    
    const close = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        details.classList.remove('open');
        if (tl) {
            tl.kill();
        }
        const duration = !reduceMotion ? 0.3 : 0;
        tl = gsap.timeline({
            onComplete() {
                details.open = false;
                gsap.set(content, { clearProps: 'height,opacity' });
            }
        })
            .to(content, { height: 0, duration }, 0)
            .to(content, { opacity: 0, duration }, 0);
    };
    
    const onClick = e => {
        e.preventDefault();
        if (isOpen) {
            close();
        } else {
            open();
        }
    };
    
    summary.addEventListener('click', onClick);
    
    const onReduceMotionChange = (key, data) => {
        reduceMotion = data.reduceMotion;
    };
    
    Dispatch.on(REDUCED_MOTION_CHANGED, onReduceMotionChange, true);

    return {
        destroy() {
            if (tl) {
                tl.kill();
            }
            summary.removeEventListener('click', onClick);
            Dispatch.off(REDUCED_MOTION_CHANGED, onReduceMotionChange);
        }
    };

};
